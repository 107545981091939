import React from 'react'


const Header = () => {

return(
    <div className= "header">
        <img classname= "logo" src={require("../images/SpencerBlumlogo.png")} height = "20px"/>
        {/* <a href="https://drive.google.com/file/d/1cCwsRbW-ieGuPwFU1GbLeaSRWas9CghR/view?usp=sharing"><button>Resume</button></a> */}
    </div>
)
 }

 export default Header;
