import React from 'react'


const Footer = () => {

return(
    <div className= "footer">
        <img classname= "logo" src={require("../images/SpencerBlumlogo.png")} height = "20px"/>
    </div>
)
 }

 export default Footer;
